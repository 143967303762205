import React, {useContext, useEffect, useRef, useState} from 'react';
import CreateClientDialog from "../create/CreateClientDialog";
import SaleDialog from "../descriptionDialog/SaleDialog";
import {catchErrorAnalitycs, fetchWithAuth, host} from "../utils/Constants";
import SaleProductItem from "./SaleProductItem";

const Sale = (data) => {
	const dialog = useRef();

	const [satisfaction, setSatisfaction] = useState([]);

	useEffect(() => {

		fetchWithAuth(host + "satisfaction/" + data.data._id + "/")
			.then(function(response) {
				if (response.ok) {return response.json();}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				let jsonData = Object.values(data)

				let dic = [
					{name: "Comentarios", subtitle: jsonData[1]},
					{name: "Motivo", subtitle: jsonData[2]},
					{name: "Satisfaccion", subtitle: jsonData[4]},
				]

				setSatisfaction(dic)
			})
			.catch(catchErrorAnalitycs);

	}, []);


	const edit = () => {
		let url = host + "sales/" + data.data._id + "/?"
		fetchWithAuth(url, {method: 'DELETE'})
			.then(function(response) {
			})
			.catch(catchErrorAnalitycs);
	}

	return (
		<>
			<tr onClick={() => dialog.current.showModal()}>
				<td><span>{data.data.name}</span></td>
				<td><span>{data.data.payment_method}</span></td>
				<td style={{width: "500px", maxWidth: "500px", whiteSpace: "normal"}}>{data.data.products}</td>
				<td><span>{data.data.date}</span></td>
				<td><span>{data.data.total}</span></td>
				<td>
					<a type="button" className="btn btn-primary light btn-sm me-1" onClick={() => {
						edit()
					}}>
						Eliminar
					</a>
				</td>
			</tr>

			<SaleDialog
				ref={dialog}
				Title="Satisfacción"
				data={satisfaction}
			/>
		</>
	);

}
export default Sale;