import React, {useContext, useEffect, useReducer, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Modal} from "react-bootstrap";
import CreateVariantDialog from "../create/CreateVariantDialog";
import {fetchWithAuth, host} from "../utils/Constants";

const Variant = (data) => {
    const navigate = useNavigate();
    const dialog = useRef();

    const restartQR = () => {
        document.getElementById("img" + data.data._id).src = ""
        document.getElementById("img" + data.data._id).src = host + "qr/?url=" + data.data._id + "&token=" + localStorage.getItem("userToken")
    }

    const deleteVariant = () => {
        if (window.confirm("Quiere eliminar esta variante ?")) {
            let url = host + "products/variants/" + data.data._id + "/"
            fetchWithAuth(url, {method: 'DELETE'})
                .then(function(response) {
                    window.location.reload();
                })
                .catch(function(error) {
                    console.log(error);
                });

        }
    }

    return (
        <>
            <tr>
                <td><span>{data.data.name.split(" - ")[0]}</span></td>
                <td><span>{data.data.name.split(" - ")[1]}</span></td>
                <td><span>{data.data.stock}</span></td>
                <td><span>{data.data.sales}</span></td>
                <td><img style={{width: "75px", padding: "0", margin: "0"}} src={host + "qr/?url=" + data.data._id + "&token=" + localStorage.getItem("userToken")} id={"img" + data.data._id }/></td>
                <td>
                    <button onClick={() => restartQR()} style={{marginTop: "10px"}} type="button" className="btn btn-primary p-2">QR</button>
                    <button onClick={() => dialog.current.showModal()} style={{marginTop: "10px", marginLeft: "20px"}} type="button" className="btn btn-primary p-2">Editar</button>
                    <button onClick={() => deleteVariant()} style={{marginTop: "10px", marginLeft: "20px"}} type="button" className="btn btn-primary p-2">Borrar</button>
                </td>
            </tr>

            <CreateVariantDialog
                ref={dialog}
                Title="Editar variante"
                product_id={data.product_id}
                id={data.data._id}
                edit={true}
                talles={data.talles}
                colores={data.colores}
            />
        </>
    );

}
export default Variant;