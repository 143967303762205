import React, {useContext, useEffect, useRef, useState} from 'react';
import CreateClientDialog from "../create/CreateClientDialog";
import SaleDialog from "../descriptionDialog/SaleDialog";
import {fetchWithAuth, host} from "../utils/Constants";
import SaleProductItem from "./SaleProductItem";
import {useNavigate} from "react-router-dom";

const Order = (data) => {
    const navigate = useNavigate();

    function showOrder() {
        navigate("/order/?id=" + data.data._id)
    }

    return (
        <>
            <tr>
                <td><span>{data.data.client}</span></td>
                <td><span>{data.data.price}</span></td>
                <td><span>{data.data.payment_status}</span></td>
                <td><span>{data.data.shipping_status}</span></td>
                <td><span>{data.data.creation_date}</span></td>
                <td>
                    <button type="button" className="btn btn-primary btn-sm me-2" onClick={() => showOrder()}>
                        Ver mas
                    </button>
                </td>
            </tr>
        </>
    );

}
export default Order;