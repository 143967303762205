import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import {catchErrorAnalitycs, fetchWithAuth, host} from "../utils/Constants";
import Select from "react-select";

const CreateCurvaDialog = forwardRef((props, ref) => {

    const [addEmploye , setAddEmploye] = useState(false)

    const [filteredTalles , setFilteredTalles] = useState([]);

    const [color, setColor] = useState(null);

    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
        showModal() {
            setAddEmploye(true)
        }    
    }));

    const handleColor = (e) => {
        setColor(e.value)
    }

    const createItem = () => {
        let url = host + "curva/" + props.product.id + `/${color}/?`

        let counter = 0
        for (let talle in filteredTalles) {
            let stock = parseInt(document.getElementById(`${filteredTalles[talle]}_input`).value);

            if (!isNaN(stock) && stock > 0) {
                url += `talle${counter}=${filteredTalles[talle]}&value${counter}=${stock}&`;
                counter++;
            }
        }

        url = url.slice(0, -1);

        fetchWithAuth(url, {method: 'POST'})
            .then(function(response) {
		    window.location.reload();
		    setAddEmploye(false)
            })
            .catch(catchErrorAnalitycs);
    }

    useEffect(() => {
        if (props.product) {
            if (props.product.category === "Pantalones") {
                setFilteredTalles(props.talles.filter(talle => !isNaN(Number(talle.value))).map(talle => talle.value))
            } else {
                setFilteredTalles(props.talles.filter(talle => isNaN(Number(talle.value))).map(talle => talle.value))
            }
        }
    }, [props.product])

    return (
        <>
            <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
					<h5 className="modal-title" id="#gridSystemModal">{props.Title}</h5>
					<button type="button" className="btn-close" 
						onClick={()=>setAddEmploye(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div className="offcanvas-body">
                    <div className="container-fluid">

                        <div className="col-xl-6 mb-3">
                            <label className="form-label">Color <span className="text-danger">*</span></label>
                            <Select id="color" defaultValue="" options={props.colores} onChange={handleColor}/>
                        </div>

                        <table id="reports-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                            <thead>
                            <tr>
                                <th>Talle</th>
                                <th>Cantidad</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                filteredTalles.map((item) => {
                                    return (
                                        <tr>
                                            <td>{item}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id={`${String(item)}_input`}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>

                        <div>
                            <button onClick={() => {
                                createItem()
                            }} className="btn btn-primary me-1">Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </Offcanvas>
        </>
    );
});

export default CreateCurvaDialog;
