import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {catchErrorAnalitycs, fetchWithAuth, host} from "../utils/Constants";

const StockLog = (data) => {
	const navigate = useNavigate();

	const [name, setName] = useState([]);

	useEffect(() => {

		fetchWithAuth(host + "products/variants/" + data.data.variant_id + "/")
			.then(function(response) {
				if (response.ok) {return response.json();}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				setName(data.name)
			})
			.catch(catchErrorAnalitycs);
	}, []);

	return (
		<>
			<tr>
				<td><span>{ name }</span></td>
				<td><span>{ data.data.creation_date }</span></td>
				<td><span>{ data.data.stock }</span></td>
			</tr>
		</>
	);

}
export default StockLog;