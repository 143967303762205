import React, {useContext, useEffect, useRef, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import {Card, Col, Nav, Tab} from "react-bootstrap";
import Sale from "./items/Sale";
import Client from "./items/Client";
import {useNavigate} from "react-router-dom";
import {Link} from "react-scroll";
import CreateClientDialog from "./create/CreateClientDialog";
import ListItem from "./items/ListItem";
import CreateItemDialog from "./create/CreateItemDialog";
import {getList} from "./utils/Utils";
import ListItemTable from "./objects/ListItemTable";

const Lists = () => {
	const navigate = useNavigate();

	const categoriesD = useRef();
	const marcaD = useRef();
	const talleD = useRef();
	const banksD = useRef();
	const nombresD = useRef();
	const coloresD = useRef();
	const sellersD = useRef();
	const tipoPrendaD = useRef();

	const [categories, setCategories] = useState([]);
	const [talles, setTalles] = useState([]);
	const [banks, setBanks] = useState([]);
	const [marcas, setMarcas] = useState([]);
	const [nombres, setNombres] = useState([]);
	const [colors, setColors] = useState([]);
	const [sellers, setSellers] = useState([]);
	const [tipoPrenda, setTipoPrenda] = useState([]);

	useEffect(() => {
		getList("sellers", (items) => {  setSellers(items)})
		getList("categories", (items) => {  setCategories(items)})
		getList("nombres", (items) => {  setNombres(items)})
		getList("marcas", (items) => {  setMarcas(items)})
		getList("banks", (items) => {  setBanks(items)})
		getList("colors", (items) => {  setColors(items)})
		getList("talles", (items) => {  setTalles(items)})
		getList("tipoPrenda", (items) => {  setTipoPrenda(items)})

	}, []);

	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />

			<div className='container-fluid'>
				<div className="col-xl-9 wid-100">
					<div className="row">
						<div className="col-xl-4">
							<ListItemTable modal={sellersD} data={sellers} list="sellers" title="Vendedores"/>
						</div>
						<div className="col-xl-4">
							<ListItemTable modal={categoriesD} data={categories} list="categories" title="Categorias"/>
						</div>
						<div className="col-xl-4">
							<ListItemTable modal={tipoPrendaD} data={tipoPrenda} list="tipoPrenda" title="Tipos de Prenda"/>
						</div>
						<div className="col-xl-4">
							<ListItemTable modal={marcaD} data={marcas} list="marcas" title="Marcas"/>
						</div>
						<div className="col-xl-4">
							<ListItemTable modal={nombresD} data={nombres} list="nombres" title="Nombres Default"/>
						</div>
						<div className="col-xl-4">
							<ListItemTable modal={talleD} data={talles} list="talles" title="Talles"/>
						</div>
						<div className="col-xl-4">
							<ListItemTable modal={banksD} data={banks} list="banks" title="Bancos"/>
						</div>
						<div className="col-xl-4">
							<ListItemTable modal={coloresD} data={colors} list="colors" title="Colores"/>
						</div>
					</div>
				</div>
			</div>

			<CreateItemDialog ref={banksD} Title="Crear Banco" list="banks"/>
			<CreateItemDialog ref={categoriesD} Title="Crear Categoria" list="categories"/>
			<CreateItemDialog ref={talleD} Title="Crear Talle" list="talles"/>
			<CreateItemDialog ref={nombresD} Title="Crear Nombre Default" list="nombres"/>
			<CreateItemDialog ref={coloresD} Title="Crear Color" list="colors"/>
			<CreateItemDialog ref={marcaD} Title="Crear Marca" list="marcas"/>
			<CreateItemDialog ref={sellersD} Title="Crear Vendedor" list="sellers"/>
			<CreateItemDialog ref={tipoPrendaD} Title="Crear Tipo de Prenda" list="tipoPrenda"/>
		</>
	);

}
export default Lists;