export const host = "https://api.vienasma.com/api/v1/"

function ensureTrailingSlash(url) {
    if (!url.endsWith('/') && !url.includes("?")) {
        url += '/';
    }
    return url;
}

export const fetchWithAuth = async (url, options = {}) => {
    const token = localStorage.getItem("userToken")

    if (typeof url === 'string') {
        url = ensureTrailingSlash(url)
    }

    const headers = {
        ...(options.headers && options.headers["Content-Type"] ? {} : { "Content-Type": "application/json" }),
        ...(token ? { "Authorization": `Bearer ${token}` } : {}),
        ...options.headers,
    };

    const fetchOptions = {
        ...options,
        headers,
    };

    return fetch(url, fetchOptions);
};

export async function handleExport(url, defaultFilename) {
    try {
        // Fetch the file with authorization
        const response = await fetchWithAuth(url, {
            method: 'GET',
        });

        // Check if the response is okay
        if (!response.ok) {
            const errorMessage = await response.text();
            throw new Error(`Error al descargar el archivo: ${errorMessage}`);
        }

        // Get the filename from the Content-Disposition header if available
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = defaultFilename;

        if (contentDisposition && contentDisposition.includes('filename=')) {
            filename = contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '').trim();
        }

        // Convert response to a Blob
        const blob = await response.blob();
        const urlBlob = window.URL.createObjectURL(blob);

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', filename || 'download');

        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(urlBlob);

    } catch (error) {
        console.error(error.message);
        alert('Hubo un problema al descargar el archivo.');
    }
};
