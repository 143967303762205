import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-scroll";
import {fetchWithAuth, host} from "../utils/Constants";

const OnlyTable = (props) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [items, setItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    function getData (page) {
        setItems([])
        fetchWithAuth(host + props.endpoint + "/" + page + "/20/")
            .then(function(response) {
                if (response.ok) {return response.json();}
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                setItems(props.formatData(data))
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    useEffect(() => {
        setItems([])
        setTotalPages(0)

        getData(1)

        fetchWithAuth(host + "utils/" + (props.totalPagesCustomValue ? props.totalPagesCustomValue : props.endpoint) + "/total_pages/20/" + (props.filter ? "?" + props.filter : ""))
            .then(function(response) {
                if (response.ok) {return response.json();}
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                setTotalPages(data.total_pages)
            })
            .catch(function(error) {
                console.log(error);
            });

    }, []);

    const [sort, setSort] = useState({keyToSort: "MAKE", direction: "asc"})

    function prePage (){
        if(currentPage - 1 >= 1){
            setCurrentPage(currentPage - 1)
            getData(currentPage - 1)
        }
    }
    function changeCPage (newPage){
        setCurrentPage(newPage)
        getData(newPage)
    }
    function nextPage (){
        if(currentPage + 1 <= totalPages){
            setCurrentPage(currentPage + 1)
            getData(currentPage + 1)
        }
    }

    function handleHeaderClick (header) {
        setSort({
            keyToSort: header,
            direction: header === sort.keyToSort ? sort.direction === "asc" ? "desc" : "asc" : "desc"
        })
    }

    return (
        <div id="manage-tblwrapper" className="dataTables_wrapper no-footer">
            <table id="reports-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                <thead>
                <tr>
                    { props.tableColumns.map((data) => {return <th onClick={() => handleHeaderClick(data.key)}>{data.value}</th>}) }
                </tr>
                </thead>
                <tbody id="table">
                { items.map((data) => {return props.dataItem(data)}) }
                </tbody>

            </table>
            <div className="d-sm-flex text-center justify-content-between align-items-center">
                <div className="dataTables_paginate paging_simple_numbers justify-content-center" id="example2_paginate">
                    <Link className="paginate_button previous disabled" to="#" onClick={prePage}>
                        <i className="fa-solid fa-angle-left" />
                    </Link>
                    <span>
                        {
                            Array.from({ length: totalPages }, (_, i) => i + 1).map((n , i )=>(
                                <Link className={`paginate_button ${currentPage === String(n) ? 'current' :  '' } `} key={i} onClick={()=>changeCPage(n)}>{n}</Link>
                            ))
                        }
                    </span>
                    <Link className="paginate_button next" to="#" onClick={nextPage}>
                        <i className="fa-solid fa-angle-right" />
                    </Link>
                </div>
            </div>
        </div>
    );

}
export default OnlyTable;