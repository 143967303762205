import React, {useRef} from 'react';
import {useNavigate} from "react-router-dom";
import CreateClientDialog from "../create/CreateClientDialog";

const Client = (data) => {
	const navigate = useNavigate();

	const dialog = useRef();

	const show = () => {
		navigate("/client/?id=" + data.data._id)
	}

	return (
		<>
			<tr onClick={() => dialog.current.showModal()}>
				<td><span>{ data.data.dni !== "null" ? data.data.dni : ""}</span></td>
				<td><span>{ data.data.name !== "null" ? data.data.name : ""}</span></td>
				<td><span>{ data.data.email !== "null" ? data.data.email : ""}</span></td>
				<td><span>{ data.data.phone !== "null" ? data.data.phone : ""}</span></td>
				<td><span>I.@{data.data.instagram !== "null" ? data.data.instagram : ""} F.@{data.data.facebook !== "null" ? data.data.facebook : ""}</span></td>
				<td>
					<button onClick={() => show()} style={{marginTop: "10px", marginLeft: "20px"}}
							type="button" className="btn btn-primary p-2">Ver mas
					</button>
				</td>
			</tr>

			<CreateClientDialog
				ref={dialog}
				Title="Editar cliente"
				edit={true}
				id={data.data._id}
			/>
		</>
	);

}
export default Client;