import React, { useState } from "react";
import {host} from "../utils/Constants";

const ImageUpload = ({ label, imagePreview, onImageSelect, id}) => {

    const shouldShowImage = (id !== null || imagePreview !== null) && imagePreview;

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                onImageSelect(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            onImageSelect(null);
        }
    };

    return (
        <div style={{width: "30%", boxSizing: "border-box", textAlign: "center"}}>
            <label>{label}</label>
            <div
                className="dz-default dlab-message upload-img mb-1"
                style={{
                    border: "2px dashed #DADADA",
                    padding: "2px",
                    position: "relative",
                    width: "100%",     // Asegura que el div ocupe el ancho completo disponible
                    paddingBottom: "100%", // Crea un espacio cuadrado
                    overflow: "hidden", // Oculta el desbordamiento de la imagen
                    boxSizing: "border-box",
                }}
            >
                <div className="fallback">
                    <input
                        type="file"
                        onChange={handleImageChange}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                        }}
                    />
                </div>
                {shouldShowImage && (
                    <img
                        src={id ? (imagePreview ? imagePreview : host + "images/download/" + id + "/&token=" + localStorage.getItem("userToken")) : imagePreview}
                        alt="Preview"
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover", // Ajusta la imagen para cubrir todo el contenedor cuadrado
                            transform: "translate(-50%, -50%)", // Centra la imagen en el contenedor
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default ImageUpload;
