import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {catchErrorAnalitycs, fetchWithAuth, host} from "../utils/Constants";

const Product = (data) => {
	const navigate = useNavigate();

	const show = () => {
		window.open("/product/?id=" + data.data._id, "_blank");
	}

	const edit = () => {
		let url = host + "products/" + data.data._id + "/?"

		let name = document.getElementById("name" + data.data._id).value
		if (name !== null && name !== "") {
			url += "name=" + name + "&"
		}

		let price = document.getElementById("price" + data.data._id).value
		if (price !== null && price !== "") {
			url += "price=" + price + "&"
		}

		fetchWithAuth(url, {method: 'PUT'})
			.then(function(response) {
			})
			.catch(catchErrorAnalitycs);
	}

	return (
		<>
			<tr>
				<td><span><input type="text" className="form-control" id={"name" + data.data._id} placeholder={data.data.name} /></span></td>
				<td><span><input type="text" className="form-control" id={"price" + data.data._id} placeholder={data.data.price} /></span></td>
				<td onClick={() => show()}><span>{data.data.marca}</span></td>
				<td onClick={() => show()}><span>{data.data.stock}</span></td>
				<td onClick={() => show()}><span>{data.data.variants}</span></td>
				<td>
					<a type="button" className="btn btn-primary light btn-sm me-1" onClick={() => {edit()}}>
						Editar
					</a>
				</td>
			</tr>
		</>
	);

}
export default Product;