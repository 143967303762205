import React, {useContext, useEffect, useRef, useReducer, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useNavigate} from "react-router-dom";

import MainPagetitle from "../../../layouts/MainPagetitle";
import {catchErrorAnalitycs, fetchWithAuth, host} from "../utils/Constants";
import TableItems from "../objects/TableItems";
import Variant from "../items/Variant";
import Sale from "../items/Sale";
import TextSquareBox from "../objects/TextBox";
import OnlyTable from "../objects/OnlyTable";
import {Link} from "react-scroll";

const OrderDescription = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [data, setData] = useState(null);

    let id = searchParams.get("id")

    function addShipping() {
        if (document.getElementById("shipping_number").value) {
            fetchWithAuth(host + "orders/" + id + "/" + document.getElementById("shipping_number").value + "/", {method: "POST"})
                .then(function(response) {return response.json();})
                .then(function(data) {
                    let newData = data
                    newData["shipping_status"] = "shipped"
                    newData["status"] = "shipped"
                    setData(newData)
                })
                .catch(catchErrorAnalitycs)
        } else {
            alert("Complete el numero de envio.")
        }
    }

    function closeOrder() {
        fetchWithAuth(host + "orders/" + id + "/finish/", {method: "POST"})
            .then(function(response) {return response.json();})
            .then(function(data) {
                let newData = data
                newData["status"] = "done"
                setData(newData)
            })
            .catch(catchErrorAnalitycs)
    }

    useEffect(() => {
        fetchWithAuth(host + "orders/" + id + "/")
            .then(function(response) {return response.json();})
            .then(function(data) {

                let cart = []
                for (let item in data.cart) {
                    cart.push({
                        "name" : data.cart[item].title,
                        "quantity" : data.cart[item].quantity,
                        "price" : data.cart[item].price
                    })
                }

                setData({
                    "_id": data._id,
                    "cart": cart,
                    "notes": data.notes,
                    "shipping_status": data.shipping_status ? data.shipping_status : "",
                    "price": data.price ? data.price : "",
                    "bill_data": {
                        "name": data.bill_name,
                        "address": data.bill_address,
                        "email": data.bill_email,
                        "phone": data.bill_phone,
                    },
                    "shipping_data": {
                        "name": data.shipping_name,
                        "phone": data.shipping_phone,
                        "email": data.shipping_email,
                        "address": data.shipping_address,
                    },
                    "status": data.status,
                    "client": data.client,
                    "creation_date": data.creation_date,
                    "payment_link": data.payment_link,
                    "payment_status": data.payment_status
                })
                console.log(data)
            })
            .catch(catchErrorAnalitycs)

    }, []);

    return (
        <>
            <MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />
            <div className='container-fluid'>
                <div className='row'>

                    {
                        !data ? (<></>) : (
                            <>
                                <TextSquareBox data={{"title": "Pago", "content": data.payment_status}}/>
                                <TextSquareBox data={{"title": "Envio", "content": data.shipping_status}}/>
                                <TextSquareBox data={{"title": "Estado", "content": data.status}}/>

                                <TextSquareBox data={{"title": "Total", "content": data.price}}/>
                                <TextSquareBox data={{"title": "Fecha", "content": data.creation_date}}/>

                                {
                                    !data.notes ? (<></>) : (
                                        <div className="col-xl-20 col-sm-6">
                                            <div className="card box-hover">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="total-projects ms-3">
                                                            <h6 className={`project-counter count`}>
                                                                {data.notes}
                                                            </h6>
                                                            <h6>Notas</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    data.shipping_status !== "pending" && data.shipping_status ? (<></>) : (
                                        <div className="col-xl-12">
                                            <div className="card">
                                                <div className="card-body p-0">
                                                    <div style={{padding: "20px"}}>

                                                        <div className="col-xl-2 mb-3">
                                                            <label htmlFor="exampleFormControlInput1"
                                                                   className="form-label">Numero de Envio</label>
                                                            <input type="text" className="form-control"
                                                                   id="shipping_number"
                                                                   placeholder=""/>
                                                        </div>
                                                        <button className="btn btn-primary btn-sm me-2"
                                                                onClick={() => addShipping()}>Agregar numero de Envio
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <div style={{padding: "20px"}}>
                                                <button className="btn btn-primary btn-sm me-2"
                                                        onClick={() => closeOrder()}>Finalizar Pedido
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <div className="table-responsive active-projects manage-client">
                                                <div
                                                    className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                                    <h4 className="heading mb-0">Direcciones</h4>
                                                    <div
                                                        className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                                    </div>
                                                </div>

                                                <div id="manage-tblwrapper" className="dataTables_wrapper no-footer">
                                                    <table id="reports-tbl"
                                                           className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                                        <thead>
                                                        <tr>
                                                            {["Tipo", "Nombre", "Telefono", "Email", "Address"].map((data) => {
                                                                return <th>{data}</th>
                                                            })}
                                                        </tr>
                                                        </thead>
                                                        <tbody id="table">
                                                        {[data.bill_data, data.shipping_data].map((data, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{index === 0 ? "Facturacion" : "Envio"}</td>
                                                                    <td>{data.name}</td>
                                                                    <td>{data.phone}</td>
                                                                    <td>{data.email}</td>
                                                                    <td>{data.address}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>

                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <div className="table-responsive active-projects manage-client">
                                                <div
                                                    className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                                    <h4 className="heading mb-0">Carrito</h4>
                                                    <div
                                                        className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                                    </div>
                                                </div>

                                                <div id="manage-tblwrapper" className="dataTables_wrapper no-footer">
                                                    <table id="reports-tbl"
                                                           className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                                        <thead>
                                                        <tr>
                                                            {["Nombre", "Cantidad", "Precio"].map((data) => {
                                                                return <th>{data}</th>
                                                            })}
                                                        </tr>
                                                        </thead>
                                                        <tbody id="table">

                                                        {data.cart.map((data, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{data.name}</td>
                                                                    <td>{data.quantity}</td>
                                                                    <td>{data.price}</td>
                                                                </tr>
                                                            )
                                                        })}

                                                        </tbody>

                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        )
                    }
                </div>
            </div>
        </>
    );

}

export default OrderDescription;