import React, {useContext, useEffect, useRef, useState} from 'react';

import {Card, Col, Nav, Tab} from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import {catchErrorAnalitycs, fetchWithAuth, host} from "../utils/Constants";
import {SVGICON} from "../../../constant/theme";
import DepositlineChart from "../graphics/DepositlineChart";

const StatusByType = (data) => {

    const [challenge, setChallenge] = useState("");

    const [totalSales, setTotalSales] = useState("");
    const [totalStock, setTotalStock] = useState("");

    const [last30DaysSales, setLast30DaysSales] = useState(0);
    const [last30DaysStock, setLast30DaysStock] = useState(0);

    const [last7DaysSales, setLast7DaysSales] = useState(0);
    const [last7DaysStock, setLast7DaysStock] = useState(0);

    const chartRef = useRef();

    const [series, setSeries] = useState([
        {
            name: 'Cuentas',
            type: 'column',
            data: []
        }, {
            name: 'Stock',
            type: 'area',
            data: []
        }
    ])

    let [options, setOptions] = useState({
        chart: {
            height: 300,
            type: 'line',
            stacked: false,
            toolbar: {
                show: false,
            },
        },
        stroke: {
            width: [0, 1, 1],
            curve: 'straight',
            dashArray: [0, 0, 5]
        },
        legend: {
            fontSize: '13px',
            fontFamily: 'poppins',
            labels: {
                colors:'#888888',
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '18%',
                borderRadius:6	,
            }
        },
        fill: {
            type : 'gradient',
            gradient: {
                inverseColors: false,
                shade: 'light',
                type: "vertical",
                colorStops : [
                    [
                        {
                            offset: 0,
                            color: 'var(--primary)',
                            opacity: 1
                        },
                        {
                            offset: 100,
                            color: 'var(--primary)',
                            opacity: 1
                        }
                    ],
                    [
                        {
                            offset: 0,
                            color: '#3AC977',
                            opacity: 1
                        },
                        {
                            offset: 0.4,
                            color: '#3AC977',
                            opacity: .15
                        },
                        {
                            offset: 100,
                            color: '#3AC977',
                            opacity: 0
                        }
                    ],
                    [
                        {
                            offset: 0,
                            color: '#FF5E5E',
                            opacity: 1
                        },
                        {
                            offset: 100,
                            color: '#FF5E5E',
                            opacity: 1
                        }
                    ],
                ],
                stops: [0, 100, 100, 100]
            }
        },
        colors:["var(--primary)","#3AC977","#FF5E5E"],
        labels: [],
        markers: {
            size: 0
        },
        xaxis: {
            type: 'month',
            labels: {
                style: {
                    fontSize: '13px',
                    colors:'#888888',
                },
            },
        },
        yaxis: {
            min: 0,
            tickAmount: 4,
            labels: {
                style: {
                    fontSize: '13px',
                    colors:'#888888',
                },
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }
        }
    })

    useEffect(() => {

        fetchWithAuth(host + "challenges/get_current_month_value/")
            .then(function(response) {
                if (response.ok) {return response.json();}
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                setChallenge(data.value)
            })
            .catch(catchErrorAnalitycs);

        fetchWithAuth(host + "sales/sales_and_stock_totals_per_week/")
            .then(function(response) {
                if (response.ok) {return response.json();}
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                let totalSalesCounter = 0
                for (let sale in Object.values(data.sales)) {
                    totalSalesCounter += Object.values(data.sales)[sale]
                }

                let totalStockCounter = 0
                for (let stock in Object.values(data.stock)) {
                    totalStockCounter += Object.values(data.stock)[stock]
                }

                setLast7DaysStock(totalStockCounter + " Unidades")

                let formattedTotalSales = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(totalSalesCounter);
                formattedTotalSales = formattedTotalSales.replace('ARS', '$')
                setLast7DaysSales(formattedTotalSales);
            })
            .catch(catchErrorAnalitycs);

        fetchWithAuth(host + "sales/sales_and_stock_per_month/")
            .then(function(response) {
                if (response.ok) {return response.json();}
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                setLast30DaysSales(data.sales)
                setLast30DaysStock(data.stock)
                let salesKeys = Object.keys(data.sales);

                let last6MonthSalesKeys = salesKeys.slice(-6);

                const monthTranslations = {
                    "january": "Enero",
                    "february": "Febrero",
                    "march": "Marzo",
                    "april": "Abril",
                    "may": "Mayo",
                    "june": "Junio",
                    "july": "Julio",
                    "august": "Sgosto",
                    "september": "Deptiembre",
                    "october": "Octubre",
                    "november": "Noviembre",
                    "december": "Diciembre"
                };


                let labelsList = []
                for (let labelsNotFormated in last6MonthSalesKeys) {
                    labelsList.push(monthTranslations[last6MonthSalesKeys[labelsNotFormated].split("-")[2].toLowerCase()])
                }

                setOptions(prevOptions => ({
                    ...prevOptions,
                    xaxis: {
                        ...prevOptions.xaxis,
                        categories: labelsList,
                    }
                }));


                let salesEntries = Object.entries(data.sales);
                let stockEntries = Object.entries(data.stock);

                let last6MonthSalesEntries = salesEntries.slice(-6);
                let last6MonthStockEntries = stockEntries.slice(-6);
                let last6MonthSales = last6MonthSalesEntries.map(entry => entry[1]);
                let last6MonthStock = last6MonthStockEntries.map(entry => entry[1]);

                let last1MonthSalesEntries = salesEntries.slice(-1);
                let last1MonthStockEntries = stockEntries.slice(-1);
                let last1MonthSales = last1MonthSalesEntries.map(entry => entry[1]);
                let last1MonthStock = last1MonthStockEntries.map(entry => entry[1]);

                let totalSalesCounter = 0
                for (let lastSales in last1MonthSales) {
                    totalSalesCounter += last1MonthSales[lastSales]
                }
                let formattedTotalSales = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(totalSalesCounter);
                formattedTotalSales = formattedTotalSales.replace('ARS', '$'); // Reemplazar 'ARS' por el símbolo de dólar
                setTotalSales(formattedTotalSales);

                let totalStockCounter = 0
                for (let lastStock in last1MonthStock) {
                    totalStockCounter += last1MonthStock[lastStock]
                }
                setTotalStock(totalStockCounter + " Unidades")

                setSeries([
                    {
                        name: 'Cuentas',
                        type: 'column',
                        data: last6MonthSales
                    },
                    {
                        name: 'Stock',
                        type: 'area',
                        data: last6MonthStock
                    }
                ])

            })
            .catch(catchErrorAnalitycs);

    }, []);



    return (
        <Card name="badges-light" className="dz-card">
            <Card.Header className="d-flex justify-content-between flex-wrap">
                <div>
                    <Card.Title>Estadisticas - {data.data}</Card.Title>
                </div>
            </Card.Header>
            <Card.Body>
                <div className="row">

                    <div className="col-xl-3 col-sm-6">
                        <div className="card chart-grd same-card">
                            <div className="card-body depostit-card p-0">
                                <div
                                    className="depostit-card-media d-flex justify-content-between pb-0">
                                    <div>
                                        <h6>Meta {data.data}</h6>
                                        <h3>{totalStock.replace(" Unidades", "")} / {challenge}</h3>
                                    </div>
                                    <div className="icon-box bg-primary-light">
                                        {SVGICON.Performance}
                                    </div>
                                </div>
                                <DepositlineChart chartcolor="var(--primary)" data={last30DaysSales}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-sm-6">
                        <div className="card chart-grd same-card">
                            <div className="card-body depostit-card p-0">
                                <div
                                    className="depostit-card-media d-flex justify-content-between pb-0">
                                    <div>
                                        <h6>Ventas de la Semana - Stock</h6>
                                        <h3>{last7DaysStock}</h3>
                                    </div>
                                    <div className="icon-box bg-primary-light">
                                        {SVGICON.Performance}
                                    </div>
                                </div>
                                <DepositlineChart chartcolor="var(--primary)" data={last30DaysSales}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-sm-6">
                        <div className="card chart-grd same-card">
                            <div className="card-body depostit-card p-0">
                                <div
                                    className="depostit-card-media d-flex justify-content-between pb-0">
                                    <div>
                                        <h6>Ventas de la Semana - Cuentas</h6>
                                        <h3>{last7DaysSales}</h3>
                                    </div>
                                    <div className="icon-box bg-primary-light">
                                        {SVGICON.DollerPrimary}
                                    </div>
                                </div>
                                <DepositlineChart chartcolor="var(--primary)" data={last30DaysStock}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                        <div className="card chart-grd same-card">
                            <div className="card-body depostit-card p-0">
                                <div
                                    className="depostit-card-media d-flex justify-content-between pb-0">
                                    <div>
                                        <h6>Ventas del Mes - Stock</h6>
                                        <h3>{totalStock}</h3>
                                    </div>
                                    <div className="icon-box bg-primary-light">
                                        {SVGICON.Performance}
                                    </div>
                                </div>
                                <DepositlineChart chartcolor="var(--primary)" data={last30DaysSales}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-sm-6">
                        <div className="card chart-grd same-card">
                            <div className="card-body depostit-card p-0">
                                <div
                                    className="depostit-card-media d-flex justify-content-between pb-0">
                                    <div>
                                        <h6>Ventas del Mes - Cuentas</h6>
                                        <h3>{totalSales}</h3>
                                    </div>
                                    <div className="icon-box bg-primary-light">
                                        {SVGICON.DollerPrimary}
                                    </div>
                                </div>
                                <DepositlineChart chartcolor="var(--primary)" data={last30DaysStock}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-header border-0 pb-0 flex-wrap">
                    <h4 className="heading mb-0">Ventas</h4>
                </div>
                <div className="card-body  p-0">
                    <div id="overiewChart">
                        <ReactApexChart
                            options={options}
                            series={series}
                            ref={chartRef}
                            type="line"
                            height={300}
                        />
                    </div>
                </div>
            </Card.Body>
        </Card>
    );

}
export default StatusByType;