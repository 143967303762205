import React, {useContext, useEffect, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import TableItems from "./objects/TableItems";
import {fetchWithAuth, handleExport, host} from "./utils/Constants";
import Order from "./items/Order";

const Orders = () => {

	const formatData = (data) => {
		let items = []
		for(let order in data.orders){
			console.log(order)
			items.push({
				"_id": data.orders[order]._id,
				"client": data.orders[order].client,
				"creation_date": data.orders[order].creation_date,
				"price": data.orders[order].price,
				"payment_status": data.orders[order].payment_status,
				"shipping_status": data.orders[order].shipping_status
			})
		}
		return items.reverse()
	}

	function exportItems() {
		handleExport(host + "export/orders/", "order_export.xlsx")
	}

	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />

			<div className='container-fluid'>
				<div className='row'>
					<TableItems
						title="Ordenes"
						endpoint="orders"
						tableColumns={
							[
								{
									key: "name",
									value: "Cliente"
								},
								{
									key: "total",
									value: "Total"
								},
								{
									key: "payment",
									value: "Estado del Pago"
								},
								{
									key: "shipping",
									value: "Estado del Envio"
								},
								{
									key: "creation_name",
									value: "Fecha"
								},
								{
									key: "action",
									value: "Ver mas"
								},
							]
						}

						// Button

						dataItem={ (data) => { return <Order data={data}/> } }
						formatData={ (data) => { return formatData(data) }}
					/>
					{
						/*
						button={() => { return (
								<a type="button" className="btn btn-primary light btn-sm me-1" onClick={() => exportItems()}>
									Exportar
								</a>
							)
						}}
						 */
					}
				</div>
			</div>
		</>
	);

}
export default Orders;