import {logEvent} from "firebase/analytics";
import {analitycs} from "../../../../App";
import axios from 'axios';

export const host="https://api.vienasma.com/api/v1/"

function ensureTrailingSlash(url) {
    if (!url.endsWith('/') && !url.includes("?")) {
        url += '/';
    }
    return url;
}

export const catchErrorAnalitycs = (error) => {
    logEvent(analitycs, "fetch_error",{
        message: error.message
    });
}

export const fetchWithAuth = async (url, options = {}) => {
    const token = localStorage.getItem("userToken")

    url = ensureTrailingSlash(url)
    const headers = {
        ...(options.headers && options.headers["Content-Type"] ? {} : { "Content-Type": "application/json" }),
        ...(token ? { "Authorization": `Bearer ${token}` } : {}),
        ...options.headers,
    };

    const fetchOptions = {
        ...options,
        headers,
    };

    return fetch(url, fetchOptions);
};

export const handleExport = async (endpoint, filename, setProgress = (int) => {}) => {
    try {
        const token = localStorage.getItem("userToken")
        const response = await axios.get(endpoint, {
            responseType: "blob",
            headers: {
                Authorization: `Bearer ${token}`, // Agregar el encabezado de autenticación
            },
            onDownloadProgress: (progressEvent) => {
                const total = progressEvent.total || 1;
                const percentCompleted = Math.round((progressEvent.loaded * 100) / total);
                setProgress(percentCompleted);
            },
        });

        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();

        setProgress(0);
    } catch (error) {
        console.error("Error al descargar el archivo:", error);
        setProgress(0);
    }
}