import React, {useRef} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import Client from "./items/Client";
import {useNavigate} from "react-router-dom";
import CreateClientDialog from "./create/CreateClientDialog";
import TableItems from "./objects/TableItems";
import {fetchWithAuth, handleExport, host} from "./utils/Constants";

const Clients = () => {

	const dialog = useRef();

	const formatData = (data) => {
		let items = []
		for(let i in data.clients){
			let jsonData = data.clients[i]

			items.push({
				"_id": jsonData._id,
				"dni": jsonData.dni,
				"email": jsonData.email,
				"facebook": jsonData.facebook,
				"instagram": jsonData.instagram,
				"name": jsonData.name,
				"phone": jsonData.phone,
			})
		}
		return items
	}

	function exportItems() {
		handleExport(host + "export/clients/", "stock_export.xlsx")
	}

	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />

			<div className='container-fluid'>
				<div className='row'>
					<TableItems
						title="Clientes"
						creator={dialog}
						creatorDialog="Crear cliente"
						endpoint="clients"
						tableColumns={
							[
								{
									key: "dni",
									value: "DNI"
								},
								{
									key: "name",
									value: "Nombre"
								},
								{
									key: "email",
									value: "Email"
								},
								{
									key: "phone",
									value: "Telefono"
								},
								{
									key: null,
									value: "Redes"
								},
								{
									key: null,
									value: "Detalles"
								},
							]
						}
						button={() => { return (
							<a type="button" className="btn btn-primary light btn-sm me-1" onClick={() => exportItems()}>
								Exportar
							</a>
						)
						}}
						dataItem={ (data) => { return <Client data={data}/> } }
						formatData={ (data) => { return formatData(data) }}
					/>
				</div>
			</div>

			<CreateClientDialog ref={dialog} Title="Crear cliente" edit={false}/>
		</>
	);

}
export default Clients;