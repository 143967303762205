import React, {useContext, useEffect, useRef, useState} from 'react';

const SaleProductItem = (data) => {

    useEffect(() => {

    }, []);

    return (
        <>
            <div>
                {
                    data.data.map((item => {
                        return (<>
                            {item}<br/>
                        </>)
                    }))
                }
            </div>
        </>
    );

}
export default SaleProductItem;