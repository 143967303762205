import React, {useContext, useEffect, useRef, useReducer, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useNavigate} from "react-router-dom";

import MainPagetitle from "../../../layouts/MainPagetitle";
import Variant from "../items/Variant";
import CreateProductDialog from "../create/CreateProductDialog";
import CreateVariantDialog from "../create/CreateVariantDialog";
import TableItems from "../objects/TableItems";
import CountUp from "react-countup";
import {fetchWithAuth, host} from "../utils/Constants";
import ReactApexChart from "react-apexcharts";
import {Card} from "react-bootstrap";
import {getList, makeSelectList} from "../utils/Utils";
import StockLog from "../items/StockLog";

const ProductDescription = () => {
    const dialog = useRef();
    const dialogProd = useRef();
    const [searchParams, setSearchParams] = useSearchParams();
    const [product, setProduct] = useState({});

    const [variants, setVariants] = useState([]);
    const [stockLog, setStockLog] = useState([]);

    const [stock, setStock] = useState(0);
    const [sales, setSales] = useState(0);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    let id = searchParams.get("id")

    const [talles , setTalles] = useState([]);
    const [colores , setColores] = useState([]);

    const reducer = (state, active) =>{
        switch(active.type){
            case 'largeModal'	:
                return { ...state, largeModal: !state.largeModal}
            default :
                return state;
        }
    }

    const chartRef = useRef();

    const [series, setSeries] = useState([
        {
            name: 'Ventas',
            type: 'area',
            data: []
        }
    ])

    let [options, setOptions] = useState({
        chart: {
            height: 300,
            type: 'line',
            stacked: false,
            toolbar: {
                show: false,
            },
        },
        stroke: {
            width: [0, 1, 1],
            curve: 'straight',
            dashArray: [0, 0, 5]
        },
        legend: {
            fontSize: '13px',
            fontFamily: 'poppins',
            labels: {
                colors:'#888888',
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '18%',
                borderRadius:6	,
            }
        },
        fill: {
            type : 'gradient',
            gradient: {
                inverseColors: false,
                shade: 'light',
                type: "vertical",
                colorStops : [
                    [
                        {
                            offset: 0,
                            color: 'var(--primary)',
                            opacity: 1
                        },
                        {
                            offset: 100,
                            color: 'var(--primary)',
                            opacity: 1
                        }
                    ],
                    [
                        {
                            offset: 0,
                            color: '#3AC977',
                            opacity: 1
                        },
                        {
                            offset: 0.4,
                            color: '#3AC977',
                            opacity: .15
                        },
                        {
                            offset: 100,
                            color: '#3AC977',
                            opacity: 0
                        }
                    ],
                    [
                        {
                            offset: 0,
                            color: '#FF5E5E',
                            opacity: 1
                        },
                        {
                            offset: 100,
                            color: '#FF5E5E',
                            opacity: 1
                        }
                    ],
                ],
                stops: [0, 100, 100, 100]
            }
        },
        colors:["var(--primary)","#3AC977","#FF5E5E"],
        labels: [],
        markers: {
            size: 0
        },
        xaxis: {
            type: 'month',
            labels: {
                style: {
                    fontSize: '13px',
                    colors:'#888888',
                },
            },
        },
        yaxis: {
            min: 0,
            tickAmount: 4,
            labels: {
                style: {
                    fontSize: '13px',
                    colors:'#888888',
                },
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }
        }
    })

    useEffect(() => {

        getList("talles", (items) => {  setTalles(makeSelectList(items))})
        getList("colors", (items) => {  setColores(makeSelectList(items))})

        fetchWithAuth(host + "products/" + id + "/monthly_sales")
            .then(function(response) {
                if (response.ok) {return response.json();}
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                let salesKeys = Object.keys(data.sales);

                let last6MonthSalesKeys = salesKeys.slice(-6);

                const monthTranslations = {
                    "january": "Enero",
                    "february": "Febrero",
                    "march": "Marzo",
                    "april": "Abril",
                    "may": "Mayo",
                    "june": "Junio",
                    "july": "Julio",
                    "august": "Sgosto",
                    "september": "Deptiembre",
                    "october": "Octubre",
                    "november": "Noviembre",
                    "december": "Diciembre"
                };

                let labelsList = []
                for (let labelsNotFormated in last6MonthSalesKeys) {
                    labelsList.push(monthTranslations[last6MonthSalesKeys[labelsNotFormated].split(" - ")[1].toLowerCase()])
                }

                setOptions(prevOptions => ({
                    ...prevOptions,
                    xaxis: {
                        ...prevOptions.xaxis,
                        categories: labelsList,
                    }
                }));

                let salesEntries = Object.entries(data.sales);
                let stockEntries = Object.entries(data.stock);

                let last6MonthSalesEntries = salesEntries.slice(-6);
                let last6MonthSales = last6MonthSalesEntries.map(entry => entry[1]);

                let last6MonthStockEntries = stockEntries.slice(-6);
                let last6MonthStock = last6MonthStockEntries.map(entry => entry[1]);

                setSeries([
                    {
                        name: 'Ventas',
                        type: 'column',
                        data: last6MonthSales
                    },
                    {
                        name: 'Stock',
                        type: 'area',
                        data: last6MonthStock
                    }
                ])

            })
            .catch(function(error) {
                console.log(error);
            });

        fetchWithAuth(host + "products/stock/" + id + "/")
            .then(function(response) {
                if (response.ok) {return response.json();}
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                let items = []
                for (let itemIndex in data) {
                    items.push(
                        {
                            "creation_date": data[itemIndex].creation_date,
                            "variant_id": data[itemIndex].variant_id,
                            "product_id": data[itemIndex].product_id,
                            "stock": data[itemIndex].stock
                        }
                    )
                }
                setStockLog(items)
            })
            .catch(function(error) {
                console.log(error);
            });

        fetchWithAuth(host + "products/" + id + "/")
            .then(function(response) {
                if (response.ok) {return response.json();}
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                setProduct({
                    "id": data._id,
                    "name": data.name,
                    "price": data.price,
                    "marca": data.marca,
                    "category": data.category,
                    "tipoPrenda": data.tipoPrenda,
                    "creation_date": data.creation_date ? data.creation_date : "Sin Datos"
                })
            })
            .catch(function(error) {
                console.log(error);
            });

        setData([
            {title: "Categoria", value: product.category ? product.category : "Sin categoria", colorstyle: 'success'},
            {title: "Variantes", value: variants.length, colorstyle: 'primary'},
            {title: "Stock", value: stock, colorstyle: 'purple'},
            {title: "Ventas", value: sales, colorstyle: 'purple'},
        ])

    }, []);

    const formatData = (data) => {
        let items = []

        let stockControl = 0
        let salesControl = 0

        for(let i in data.variant){
            let jsonData = data.variant[i]

            items.push(
                {
                    "_id": jsonData._id,
                    "name": jsonData.name,
                    "stock": jsonData.stock,
                    "sales": jsonData.sales,
                    "product_id": jsonData.product_id
                }
            )
            salesControl += jsonData.sales
            stockControl += jsonData.stock
        }
        setStock(stockControl)
        setSales(salesControl)
        return items
    }

    const deleteProduct = () => {
        if (window.confirm("Quiere eliminar este producto ?")) {
            let url = host + "products/" + id + "/"
            fetchWithAuth(url, {method: 'DELETE'})
                .then(function(response) {
                    navigate("/products")
                })
                .catch(function(error) {
                    console.log(error);
                });

        }
    }

    return (
        <>
            <MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />
            <div className='container-fluid'>
                <div className='row'>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects manage-client">
                                    <div
                                        className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <div style={{alignContent: "center"}}>
                                            <span style={{float: "left"}}>
                                                {
                                                    product.id ? (
                                                        <>
                                                            <img
                                                                style={{width: "100px", padding: "0", margin: "5px"}}
                                                                src={host + "images/download/" + product.id + "-1/"}
                                                                id={"img1" + product.id}
                                                                onError={(event) => {
                                                                    event.target.src = '/product-17-1.jpg'
                                                                    event.target.onerror = null
                                                                }}
                                                            />
                                                            <img style={{width: "100px", padding: "0", margin: "5px"}}
                                                                 src={host + "images/download/" + product.id + "-2/"}
                                                                 id={"img2" + product.id}
                                                                 onError={(event) => {
                                                                     event.target.src = '/product-17-1.jpg'
                                                                     event.target.onerror = null
                                                                 }}
                                                            />
                                                            <img style={{width: "100px", padding: "0", margin: "5px"}}
                                                                 src={host + "images/download/" + product.id + "-3/"}
                                                                 id={"img3" + product.id}
                                                                 onError={(event) => {
                                                                     event.target.src = '/product-17-1.jpg'
                                                                     event.target.onerror = null
                                                                 }}
                                                            /></>
                                                    ) : (<></>)
                                                }

                                            </span>
                                            <h4 style={{
                                                fontSize: "30px",
                                                float: "left",
                                                marginTop: "10%",
                                                marginLeft: "25px"
                                            }} className="heading mb-0">{product.name}</h4>
                                        </div>
                                        <div>
                                            <button onClick={() => dialog.current.showModal()} style={{float: "right"}}
                                                    type="button" className="btn btn-primary p-2">Crear variante
                                            </button>
                                            <button onClick={() => dialogProd.current.showModal()}
                                                    style={{float: "right", marginRight: "20px"}} type="button"
                                                    className="btn btn-primary p-2">Editar
                                            </button>
                                            <button onClick={() => deleteProduct()}
                                                    style={{float: "right", marginRight: "20px"}} type="button"
                                                    className="btn btn-primary p-2">Borrar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-2 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count text-${data.colorstyle}`}>{product.price}</h6>
                                        <h6>Precio</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count text-${data.colorstyle}`}>{product.category ? product.category : "Sin Categoria"}</h6>
                                        <h6>Categoria</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count text-${data.colorstyle}`}>{product.tipoPrenda ? product.tipoPrenda : "Sin Tipo"}</h6>
                                        <h6>Tipo</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count text-${data.colorstyle}`}>{product.marca}</h6>
                                        <h6>Marca</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count text-${data.colorstyle}`}>{variants.length}</h6>
                                        <h6>Variantes</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count text-${data.colorstyle}`}>{stock}</h6>
                                        <h6>Stock</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count text-${data.colorstyle}`}>{product.creation_date}</h6>
                                        <h6>Creacion</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Card name="badges-light" className="dz-card">
                    <Card.Header className="d-flex justify-content-between flex-wrap">
                        <div>
                            <Card.Title>Estadisticas</Card.Title>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="card-header border-0 pb-0 flex-wrap">
                            <h4 className="heading mb-0">Ventas</h4>
                        </div>
                        <div className="card-body  p-0">
                            <div id="overiewChart">
                                <ReactApexChart
                                    options={options}
                                    series={series}
                                    ref={chartRef}
                                    type="line"
                                    height={300}
                                />
                            </div>
                        </div>

                    </Card.Body>
                </Card>
                <TableItems
                    title="Variantes"
                    creator={dialog}
                    creatorDialog="Crear variante"
                    endpoint={"products/variants/" + id}
                    filter={"filter1=product_id&value1=" + id}
                    totalPagesCustomValue="variants"
                    tableColumns={
                        [,
                            {
                                key: "name",
                                value: "Nombre"
                            },
                            {
                                key: "talle",
                                value: "Talle"
                            },
                            {
                                key: "stock",
                                value: "Stock"
                            },
                            {
                                key: "sales",
                                value: "Ventas"
                            },
                            {
                                key: null,
                                value: "QR"
                            },
                            {
                                key: null,
                                value: "Acciones"
                            },
                        ]
                    }
                    dataItem={(data) => {
                        return <Variant data={data} product_id={id} talles={talles} colores={colores}/>
                    }}
                    formatData={ (data) => { return formatData(data) }}
                />

                <TableItems
                    title="Stock"
                    endpoint={"products/variants/" + id + "/"}
                    tableColumns={
                        [
                            {
                                key: "name",
                                value: "Variante"
                            },
                            {
                                key: "date",
                                value: "Fecha"
                            },
                            {
                                key: "stock",
                                value: "Stock"
                            }
                        ]
                    }
                    data={stockLog}
                    dataItem={(data) => {
                        return <StockLog data={data}/>
                    }}
                />

            </div>


            <CreateProductDialog
                ref={dialogProd}
                Title="Editar producto"
                id={id}
                edit={true}
            />

            <CreateVariantDialog
                ref={dialog}
                Title="Crear variante"
                edit={false}
                talles={talles}
                colores={colores}
            />
        </>
    );

}
export default ProductDescription;