import { lazy, Suspense, useEffect, useState} from 'react';
/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import {  Route, Routes, useLocation , useNavigate , useParams } from 'react-router-dom';
import { isAuthenticated } from './store/selectors/AuthSelectors';
import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import {getAnalytics} from "firebase/analytics";
import * as Sentry from "@sentry/react";

const firebaseConfig = {
    apiKey: "AIzaSyAvkkjrZwXgsCch5O07lAqvlzmoy7Q3Hnk",
    authDomain: "viena-447c3.firebaseapp.com",
    projectId: "viena-447c3",
    storageBucket: "viena-447c3.appspot.com",
    messagingSenderId: "988198027168",
    appId: "1:988198027168:web:139457c663668086d66ec5",
    measurementId: "G-5X5EG87C2R"
};

initializeApp(firebaseConfig)
export const analitycs = getAnalytics()


const Login = lazy(() => {
    return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
	
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}



function App (props) {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const auth = getAuth();

        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken(true).then((idToken) => {
                    localStorage.setItem("userToken", idToken);
                    setUser(user);
                }).catch((error) => {
                    localStorage.removeItem("userToken");
                    setUser(null);
                });
            } else {
                localStorage.removeItem("userToken");
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    let routeblog = (         
      <Routes>   
        <Route  path='/login' element={<Login />} />
        <Route  path='/' element={<Login />} />
      </Routes> 
    );


    return (
        <>
            {user ? (
                <Suspense
                    fallback={
                        <div id="preloader">
                            <div className="sk-three-bounce">
                                <div className="sk-child sk-bounce1"></div>
                                <div className="sk-child sk-bounce2"></div>
                                <div className="sk-child sk-bounce3"></div>
                            </div>
                        </div>
                    }
                >
                    <Index />
                </Suspense>
            ) : (
                <div className="vh-100">
                    <Suspense
                        fallback={
                            <div id="preloader">
                                <div className="sk-three-bounce">
                                    <div className="sk-child sk-bounce1"></div>
                                    <div className="sk-child sk-bounce2"></div>
                                    <div className="sk-child sk-bounce3"></div>
                                </div>
                            </div>
                        }
                    >
                        {routeblog}
                    </Suspense>
                </div>
            )}
        </>
    )
};


const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App)); 

