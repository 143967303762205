import React, {useRef} from 'react';
import {useNavigate} from "react-router-dom";
import CreateClientDialog from "../create/CreateClientDialog";

const TextSquareBox = (data) => {

    return !data.data.content ? (<></>) : (
        <div className="col-xl-2 col-sm-6">
            <div className="card box-hover">
                <div className="card-body">
                    <div className="d-flex align-items-center">
                        <div className="total-projects ms-3">
                            <h6
                                className={`project-counter count`}
                                style={{
                                    fontSize: `${Math.max(12, 16 - ((data.data ? data.data.content.length : 10) / 10))}px`, // Ajusta el tamaño según la longitud
                                    whiteSpace: 'nowrap', // Para evitar que el texto se divida en varias líneas
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis' // Muestra "..." si el texto es muy largo
                                }}
                            >
                                {data.data.content !== null ? data.data.content : (data.data.default_value ? data.data.default_value : "Sin Datos")}
                            </h6>
                            <h6>{data.data.title}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TextSquareBox;