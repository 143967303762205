import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import {getList, makeSelectList} from "../utils/Utils";
import Select from "react-select";
import {fetchWithAuth, host} from "../utils/Constants";
import ImageUpload from "../items/ImageUpload";

const CreateClientDialog =forwardRef((props, ref) => {
    const [addEmploye , setAddEmploye] = useState(false);
    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
        showModal() {
            setAddEmploye(true)
        }    
    }));

    const [customName , setCustomName] = useState(false)
    const [marcas , setMarcas] = useState([])
    const [nombres , setNombres] = useState([])
    const [categories , setCategories] = useState([])
    const [tiposPrenda , setTiposPrenda] = useState([])

    const [marca, setMarca] = useState(null);
    const [tipoPrenda, setTipoPrenda] = useState(null);
    const [nombre, setNombre] = useState(null);
    const [category, setCategory] = useState(null);

    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);

    useEffect(() => {
        getList("marcas", (items) => {setMarcas(makeSelectList(items))} )
        getList("categories", (items) => {setCategories(makeSelectList(items))} )
        getList("nombres", (items) => {  setNombres(makeSelectList(items))} )
        getList("tipoPrenda", (items) => {  setTiposPrenda(makeSelectList(items))} )
    }, []);

    const restartImages = () => {
        setImage1(null);
        setImage2(null);
        setImage3(null);
    }

    const uploadImage = (id, image, number) => {
        if (image) {
            return fetchWithAuth(host + "images/upload/" + id + "-" + number + "/", {method: 'POST', body: JSON.stringify({file: image })})
        } else {
            return Promise.resolve();
        }
    }

    const handleTipoPrenda = (e) => {
        setTipoPrenda(e.value)
    }

    const handleMarca = (e) => {
        setMarca(e.value)
    }

    const handleNombre = (e) => {
        setNombre(e.value)
    }

    const handleCategories = (e) => {
        setCategory(e.value)
    }

    const action = () => {
        if (props.edit) {
            editProduct(props.id)
        } else {
            createProduct()
        }
    }

    const createProduct = () => {
        let url = host + "products/?"

        if (document.getElementById("custom-name").checked) {
            url += "name=" + document.getElementById("name").value + "&"
        } else {
            url += "name=" + nombre + "&"
        }
        url += "marca=" + marca + "&"
        url += "tipoPrenda=" + tipoPrenda + "&"
        url += "category=" + category + "&"
        url += "price=" + document.getElementById("price").value + "&"
        url += "description=" + document.getElementById("description").value

        fetchWithAuth(url, {method: 'POST'})
            .then(function(response) {
                if (response.ok) {return response.json();}
                throw new Error("Error en la solicitud HTTP");
            })
            .then(async function (data) {

                const uploadPromises = [
                    uploadImage(data.inserted_id, image1, 1),
                    uploadImage(data.inserted_id, image2, 2),
                    uploadImage(data.inserted_id, image3, 3)
                ];

                await Promise.all(uploadPromises);
                restartImages()
                navigate("/product?id=" + data.inserted_id)
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    const editProduct = async (id) => {
        let url = host + "products/" + id + "/?"

        if (document.getElementById("custom-name").checked) {
            let name = document.getElementById("name").value
            if (name !== null && name !== "") {
                url += "name=" + name + "&"
            }
        } else {
            if (nombre !== null && nombre !== "") {
                url += "name=" + nombre + "&"
            }
        }

        if (category !== null && category !== "") {
            url += "category=" + category + "&"
        }

        if (marca !== null && marca !== "") {
            url += "marca=" + marca + "&"
        }

        if (tipoPrenda !== null && tipoPrenda !== "") {
            url += "tipoPrenda=" + tipoPrenda + "&"
        }

        let price = document.getElementById("price").value
        if (price !== null && price !== "") {
            url += "price=" + price + "&"
        }

        let description = document.getElementById("description").value
        if (description !== null && description !== "") {
            url += "description=" + description + "&"
        }

        if (url !== host + "products/" + id + "/?") {
            fetchWithAuth(url, {method: 'PUT'})
                .then(async function (response) {

                    const uploadPromises = [
                        uploadImage(id, image1, 1),
                        uploadImage(id, image2, 2),
                        uploadImage(id, image3, 3)
                    ];

                    await Promise.all(uploadPromises);
                    restartImages()
                    window.location.reload();
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else if (image1 || image2 || image3) {
            const uploadPromises = [
                uploadImage(id, image1, 1),
                uploadImage(id, image2, 2),
                uploadImage(id, image3, 3)
            ];

            await Promise.all(uploadPromises);
            restartImages()
            window.location.reload();
        }
    }

    const handleCustomname = (e) => {
        setCustomName(document.getElementById("custom-name").checked)
    }

    return (
        <>
            <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
					<h5 className="modal-title" id="#gridSystemModal">{props.Title}</h5>
					<button type="button" className="btn-close" 
						onClick={()=>setAddEmploye(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div className="offcanvas-body">
                    <div className="container-fluid">
                        <div className="row">

                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <ImageUpload label="Imagen 1" imagePreview={image1} onImageSelect={setImage1} id={props.id + "1"}/>
                                <ImageUpload label="Imagen 2" imagePreview={image2} onImageSelect={setImage2} id={props.id + "2"}/>
                                <ImageUpload label="Imagen 3" imagePreview={image3} onImageSelect={setImage3} id={props.id + "3"}/>
                            </div>

                            <div className="col-xl-6 mb-3" style={{display: customName === true ? "none" : "block"}}>
                                <label className="form-label">Nombre Default <span
                                    className="text-danger">*</span></label>
                                <Select id="default-name" defaultValue="" options={nombres} onChange={handleNombre}/>
                            </div>
                            <div className="col-xl-6 mb-3" style={{display: customName === true ? "block" : "none"}}>
                                <label htmlFor="exampleFormControlInput1" className="form-label">Nombre</label>
                                <input type="text" className="form-control" id="name" placeholder=""/>
                            </div>
                            <div className="form-check custom-checkbox mb-3 checkbox-danger"
                                 style={{marginLeft: "10px"}}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="custom-name"
                                    onChange={handleCustomname}
                                />
                                <label className="form-check-label">Nombre personalizado</label>
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label className="form-label">Categoria <span className="text-danger">*</span></label>
                                <Select id="category" defaultValue="" options={categories} onChange={handleCategories}/>
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label className="form-label">Marca <span className="text-danger">*</span></label>
                                <Select id="marca" defaultValue="" options={marcas} onChange={handleMarca}/>
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label className="form-label">Tipo <span className="text-danger">*</span></label>
                                <Select id="tipoPrenda" defaultValue="" options={tiposPrenda}
                                        onChange={handleTipoPrenda}/>
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Precio</label>
                                <input type="text" className="form-control" id="price" placeholder=""/>
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Descripcion</label>
                                <input type="text" className="form-control" id="description" placeholder=""/>
                            </div>

                        </div>
                        <div>
                            <button onClick={() => {
                                action()
                            }} className="btn btn-primary me-1">Aceptar
                            </button>
                            <Link to={"#"} onClick={() => {
                                setAddEmploye(false)
                            }} className="btn btn-danger light ms-1">Cancelar</Link>
                        </div>
                    </div>
                </div>
            </Offcanvas>
        </>
    );
});

export default CreateClientDialog;