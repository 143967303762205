import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-scroll";
import {catchErrorAnalitycs, fetchWithAuth, host} from "../utils/Constants";

const FindTable = (props) => {

    const [items , setItems] = useState([]);

    const [currentPage , setCurrentPage] = useState(1);
    const [totalPages , setTotalPages] = useState(0);

    const recordsPage = 50;
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;
    let records = items.slice(firstIndex, lastIndex);
    let npage = Math.ceil(items.length / recordsPage)

    function prePage (){
        if(currentPage !== 1){
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage (id){
        setCurrentPage(id);
    }
    function nextPage (){
        if(currentPage !== npage){
            setCurrentPage(currentPage + 1)
        }
    }

    const change = (e) => {
        setItems([])

        let keyword = document.getElementById("search").value

        if (keyword === "" || keyword == null) {return}
        if (keyword.length <= 3) {return}

        fetchWithAuth((host + props.endpoint + "/find/" + keyword + "/"))
            .then(function(response) {
                if (response.ok) {return response.json();}
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                setItems(props.formatData(data))
                setTotalPages(data.total_pages)
                console.log(items)
            })
            .catch(catchErrorAnalitycs);
    }

    return (
        <div className="col-xl-12">
            <div className="card">
                <div className="card-body p-0">
                    <div className="table-responsive active-projects manage-client" >
                        <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                            <div className="type-massage style-1" style={{maxWidth: "100%", width: "100%"}}>
                                <textarea id="search" rows="1" className="form-control" placeholder="Buscar..." onChange={change}></textarea>
                            </div>
                        </div>
                        <div id="manage-tblwrapper" className="dataTables_wrapper no-footer" style={{display: items.length > 0 ? "block" : "none"}}>
                            <table id="reports-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                <thead>
                                <tr>
                                    {
                                        props.tableColumns.map((data) => {
                                            return <th>{data}</th>
                                        })
                                    }
                                </tr>
                                </thead>
                                <tbody id="table">
                                {records.map((data) => {
                                    return props.dataItem(data)
                                })}
                                </tbody>

                            </table>
                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                <div
                                    className="dataTables_paginate paging_simple_numbers justify-content-center"
                                    id="example2_paginate"
                                >
                                    <Link className="paginate_button previous disabled" to="#" onClick={prePage}>
                                        <i className="fa-solid fa-angle-left" />
                                    </Link>
                                    <span>
                                        {
                                            Array.from({ length: totalPages }, (_, i) => i + 1).map((n , i )=>(
                                                <Link className={`paginate_button ${currentPage === n ? 'current' :  '' } `} key={i} onClick={()=>changeCPage(n)}>{n}</Link>
                                            ))
                                        }
                                    </span>
                                    <Link className="paginate_button next" to="#" onClick={nextPage}>
                                        <i className="fa-solid fa-angle-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default FindTable;