import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import Select from "react-select";
import {getList, makeSelectList} from "../utils/Utils";
import {catchErrorAnalitycs, fetchWithAuth, host} from "../utils/Constants";
import ImageUpload from "../items/ImageUpload";

const CreateVariantDialog =forwardRef((props, ref) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [addEmploye , setAddEmploye] = useState(false);

    const [stock , setStock] = useState([
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" }

    ]);

    const [talle, setTalle] = useState(null);
    const [color, setColor] = useState(null);
    const [stockValue, setStockValue] = useState(null);


    const handleTalle = (e) => {
        setTalle(e.value)
    }

    const handleColor = (e) => {
        setColor(e.value)
    }

    const handleStock = (e) => {
        setStockValue(parseInt(e.value, 10))
    }

    useImperativeHandle(ref, () => ({
        showModal() {
            setAddEmploye(true)
        }    
    }));

    let id = searchParams.get("id")

    async function action () {
        if (props.edit) {
            await editVariant(props.id)
        } else {
            createVariant()
        }
    }

    const editVariant = async (id) => {
        let url = host + "products/variants/" + id + "/" + props.product_id + "/?"

        if (talle !== null && talle !== "" && color !== null && color !== "") {
            url += "name=" + color + " - " + talle + "&"
        }
        if (stockValue !== null && stockValue !== "") {
            url += "stock=" + stockValue + "&"
        }

        if (url !== host + "products/variants/" + id + "/?") {
            fetchWithAuth(url, {method: 'PUT'})
                .then(function (response) {
                    window.location.reload();
                    setAddEmploye(false)
                })
                .catch(catchErrorAnalitycs);
        }
    }

    const createVariant = () => {
        if (talle !== null && talle !== "" && talle !== "Seleccionar talle" && stockValue !== null && stockValue !== "" && color !== null && color !== "") {
            const url = new URL(host + "products/variants/");
            const params = new URLSearchParams({
                name: `${color} - ${talle}`,
                stock: stockValue,
                product_id: id
            });

            url.search = params.toString();

            fetchWithAuth(url.toString(), {method: 'POST'})
                .then(function(response) {
                    if (response.ok) {return response.json();}
                    throw new Error("Error en la solicitud HTTP");
                })
                .then(async function (data) {
                    window.location.reload();
                    setAddEmploye(false)
                })
                .catch(catchErrorAnalitycs);
        } else {
            window.alert("Complete todos los campos")
        }
    }

    return (
        <>
            <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
					<h5 className="modal-title" id="#gridSystemModal">{props.Title}</h5>
					<button type="button" className="btn-close" 
						onClick={()=>setAddEmploye(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div className="offcanvas-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 mb-3">
                                <label className="form-label">Color <span className="text-danger">*</span></label>
                                <Select id="color" defaultValue="" options={props.colores} onChange={handleColor}/>
                            </div>

                            <div className="col-xl-6 mb-3">
                                <label className="form-label">Talle <span className="text-danger">*</span></label>
                                <Select id="talle" defaultValue="" options={props.talles} onChange={handleTalle}/>
                            </div>

                            <div className="col-xl-6 mb-3">
                                <label className="form-label">Stock <span className="text-danger">*</span></label>
                                <Select id="stock" defaultValue="1" options={stock} onChange={handleStock}/>
                            </div>
                        </div>
                        <div>
                            <button onClick={async () => {
                                await action()
                            }} className="btn btn-primary me-1">Aceptar
                            </button>
                            <Link to={"#"} onClick={() => {
                                setAddEmploye(false)
                            }} className="btn btn-danger light ms-1">Cancelar</Link>
                        </div>
                    </div>
                </div>
            </Offcanvas>
        </>
    );
});

export default CreateVariantDialog;