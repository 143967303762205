import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {fetchWithAuth, host} from "../utils/Constants";

const ListItem = (data) => {
    const navigate = useNavigate();

    const deleteItem = () => {
        fetchWithAuth(host + "lists/" + data.list + "/" + data.data.id, {method: 'DELETE'})
            .then(function(response) {
                window.location.reload();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    return (
        <>
            <tr>
                <td><span>{data.data.name}</span></td>
                <td>
                    <button onClick={() => deleteItem()} style={{float: "right"}} type="button" className="btn btn-primary p-2">Borrar</button>
                </td>
            </tr>
        </>
    );

}
export default ListItem;