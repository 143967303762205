import {logEvent} from "firebase/analytics";
import {analitycs} from "../../../../App";

export const host = "https://api.vienasma.com/api/v1/"

function ensureTrailingSlash(url) {
    if (!url.endsWith('/') && !url.includes("?")) {
        url += '/';
    }
    return url;
}

export const catchErrorAnalitycs = (error) => {
    logEvent(analitycs, "fetch_error",{
        message: error.message
    });
}

export const fetchWithAuth = async (url, options = {}) => {
    const token = localStorage.getItem("userToken")

    url = ensureTrailingSlash(url)
    const headers = {
        ...(options.headers && options.headers["Content-Type"] ? {} : { "Content-Type": "application/json" }),
        ...(token ? { "Authorization": `Bearer ${token}` } : {}),
        ...options.headers,
    };

    const fetchOptions = {
        ...options,
        headers,
    };

    return fetch(url, fetchOptions);
};

export async function handleExport(url, filename){
    const response = await fetchWithAuth(url, {
        method: 'GET',
    })

    if (!response.ok) {
        throw new Error('Error al descargar el archivo');
    }

    const blob = await response.blob();
    const urlBlob = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = urlBlob;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(urlBlob);
};
