import React, {useContext, useEffect, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import Sale from "./items/Sale";
import TableItems from "./objects/TableItems";
import {fetchWithAuth, handleExport, host} from "./utils/Constants";
import SaleProductItem from "./items/SaleProductItem";

const Sales = () => {

	const formatData = (data) => {
		let items = []
		for(let i in data.sales){
			let jsonData = data.sales[i]

			let products = data.sales[i].sales
			let variantsList = []
			for (let index in products) {
				variantsList.push(products[index].stock + " - " + products[index].name)
			}

			items.push({
				"_id": jsonData._id,
				"client_id": jsonData.client_id,
				"name": jsonData.client_name,
				"date": jsonData.date,
				"payment_method":jsonData.payment_method,
				"sales": jsonData.sales,
				"total": jsonData.total,
				"products": <SaleProductItem data={variantsList}/>
			})

		}
		return items
	}

	function exportItems() {
		handleExport(host + "export/sales/", "sales_export.xlsx")
	}

	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />

			<div className='container-fluid'>
				<div className='row'>
					<TableItems
						title="Ventas"
						endpoint="sales"
						tableColumns={
							[
								{
									key: "name",
									value: "Nombre"
								},
								{
									key: "payment_method",
									value: "Metodo de Pago"
								},
								{
									key: null,
									value: "Productos"
								},
								{
									key: "date",
									value: "Fecha"
								},
								{
									key: "total",
									value: "Total"
								},
								{
									key: "Acciones",
									value: "acciones"
								},
							]
						}
						button={() => { return (
								<a type="button" className="btn btn-primary light btn-sm me-1" onClick={() => exportItems()}>
									Exportar
								</a>
							)
						}}
						dataItem={ (data) => { return <Sale data={data}/> } }
						formatData={ (data) => { return formatData(data) }}
					/>
				</div>
			</div>
		</>
	);

}
export default Sales;